import { defineStore } from 'pinia';

export const useAuthStore = defineStore('auth', {
  state: () => ({
    token: localStorage.getItem('user_token') || null,
    user_ID: localStorage.getItem('user_ID') || null,
    status_ID: localStorage.getItem('status_id') || null,
  }),
  actions: {
    setToken(newToken) {
      this.token = newToken;
      localStorage.setItem('user_token', newToken);
    },
    clearToken() {
      this.token = null;
      localStorage.removeItem('user_token');
    },
    setUser_ID(newUser_ID) {
      this.user_ID = newUser_ID;
      localStorage.setItem('user_ID', newUser_ID);
    },
    clearUser_ID() {
      this.user_ID = null;
      localStorage.removeItem('user_ID');
    },
    setStatus_ID(newStatus_ID) {
      this.status_ID = newStatus_ID;
      localStorage.setItem('status_id', newStatus_ID);
    },
    clearStatus_ID() {
      this.status_ID = null;
      localStorage.removeItem('status_id');
    },
    clearAuth() {
      this.clearToken();
      this.clearUser_ID();
      this.clearStatus_ID();
    },
  },
  getters: {
    isAuthenticated: (state) => !!state.token,
    hasUser_ID: (state) => !!state.user_ID,
  },
});


export const usedUrl = window.location.hostname == 'valutix.io' || window.location.hostname == 'www.valutix.io' ? 'https://valutix.io/api/V1' : 'https://staging.valutix.io/api/V1';
