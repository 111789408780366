import { defineStore } from 'pinia';
import { useAuthStore, usedUrl } from '@/stores/useAuthStore.js';

export const useUserStore = defineStore('user', {
  state: () => ({
    user: null,
    balances: null,
    isLoading: false,
  }),
  actions: {
    async fetchUser() {
      const authStore = useAuthStore();
      this.isLoading = true;
      try {
        const response = await fetch(`${usedUrl}/users/token`, {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": `Bearer ${authStore.token}`
          },
          method: "GET",
        });
        if (response.ok) {
          const actualData = await response.json();
          this.user = actualData.data;
          this.balances = this.user?.balances.map((b) => ({
            ...b,
            finally_value: b.value / b.currency.delimiter,
          }));
          console.log, ('this.user', this.user);
        }
        else {
          alert('Не удалось получить юзера');
        }
      } catch (e) {
        console.error('Ошибка:', e);
      } finally {
        this.isLoading = false;
      }
    },
  },
});
