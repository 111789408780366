<template>
    <div class="progress-circular"></div>
</template>

<script>
export default {}
</script>

<style scoped>
.progress-circular {
    width: 60px; /* Размер круга */
    height: 60px;
    border-radius: 50%;
    border: 4px solid #e0e0e0; /* Цвет фона круга */
    border-top: 4px solid #35AD85; /* Цвет индикатора */
    animation: spin 1s linear infinite; /* Анимация вращения */
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>