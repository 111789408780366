<template>
    <img src="/static/img/access_time2.svg">
    <span class="small">{{ minutes }} мин</span>
</template>

<script>
import moment from 'moment';

export default {
props: ['data'],
data() {
    return {
        minutes: this.$route.name == 'invoice' ? 15 : 60,
        interval: null,
        created_at: this.data
    };
},
methods: {
    startTimer() {
        if (!this.created_at) return; // Если времени нет, ничего не делаем

        const endTime = moment(this.created_at).add(this.$route.name == 'invoice' ? 15 : 60, 'minutes'); // Время окончания
        console.log(endTime);
        this.updateMinutes(endTime); // Вычисляем оставшиеся минуты

        this.interval = setInterval(() => {
            this.updateMinutes(endTime);
        }, 60 * 1000);
    },
    updateMinutes(endTime) {
        const now = moment();
        const remaining = endTime.diff(now, 'minutes');

        if (remaining <= 0) {
            this.minutes = 0;
            this.clearTimer();
            this.$route.name == 'invoice' ? this.emitter.emit('finish_invoice_timer') : this.emitter.emit('finish_timer');
        } else {
            this.minutes = remaining;
        }
    },
    clearTimer() {
        clearInterval(this.interval);
    }
},
created() {
    this.startTimer();
},
beforeDestroy() {
    this.clearTimer();
}
};
</script>

<style scoped>
.small {
width: 60px;
text-align: center;
}
</style>
