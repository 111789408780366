<template v-if="history">
  <div
  class="d-flex align-items-center justify-content-center w-100 h-100"
  v-if="loading"
>
  <Circle />
</div>
  <div class="container" v-if="!loading">
    <div class="operation_history">
      <h6>История оплат</h6>
      <div v-if="paginatedElements?.length == 0">
        <p class="text-light fz-16 no-active text-center p-3">
          Вы не проводили оплаты
        </p>
      </div>
      <div class="accordion" id="pays_history_accordeon" v-else>
        <div
          v-for="item in paginatedElements"
          :key="item.id"
          class="accordion-item bg-transparent border_green"
        >
          <h2 class="accordion-header" :id="'heading' + item?.id">
            <button
              class="accordion-button shadow-none flex-row align-items-baseline bg-transparent text-light flex-nowrap collapsed"
              type="button"
              data-bs-toggle="collapse"
              :data-bs-target="'#collapse' + item?.id"
              aria-expanded="false"
              :aria-controls="'collapse' + item?.id"
            >
              <div
                class="row m-0 justify-content-between align-items-center flex-row gap-1 gap-xl-3 active_pays_header flex-lg-nowrap"
              >
                <div
                  class="p-0 col d-none d-md-flex justify-content-center align-items-center"
                >
                  <img src="/static/img/history_img.svg" />
                </div>
                <div class="p-0 col-12 col-lg-6 order-3 order-lg-2">
                  {{ item?.operation?.operation_title }}
                </div>
                <div class="p-0 col col-lg-2 order-1">
                  {{ parseFloat(item?.finallyAmount) }}
                  {{ item?.operation?.cryptocurrency_id?.code }}
                </div>
                <div
                  class="p-0 col order-2 order-lg-3 text-end me-2"
                  :class="{
                    danger: item?.operation?.status == 'Отмена',
                    success: item?.operation?.status == 'Успех',
                    'text-warning':
                      item?.operation?.status !== 'Успех' &&
                      item?.operation?.status !== 'Отмена',
                  }"
                >
                  {{ item?.operation?.status }}
                </div>
              </div>
            </button>
          </h2>
          <div
            :id="'collapse' + item.id"
            class="accordion-collapse collapse"
            :aria-labelledby="'heading' + item?.id"
            data-bs-parent="#pays_history_accordeon"
          >
            <div class="accordion-body bg_green text-light">
              <div class="row m-0 mb-1">
                <div class="p-0 col-12 col-lg-2 fz-16">ID</div>
                <div class="p-0 col-12 col-lg fz-16">
                  {{ item?.operation?.id }}
                </div>
              </div>
              <div class="row m-0" v-if="item?.operation?.status === 'Отмена'">
                <div class="p-0 col-12 col-lg-2 fz-16">Отмена</div>
                <div class="p-0 col-12 col-lg fz-16">
                  {{ item?.operation?.cancellation_message }}
                </div>
              </div>
              <template v-if="item?.operation?.status === 'Успех'">
                <div class="row m-0 mb-1">
                  <div class="p-0 col-12 col-lg-2 fz-16">Списано</div>
                  <div class="p-0 col-12 col-lg fz-16">
                    {{ parseFloat(item?.finallyAmount) }}
                    {{ item?.operation?.cryptocurrency_id?.code }}
                  </div>
                </div>
                <div class="row m-0 mb-1">
                  <div class="p-0 col-12 col-lg-2 fz-16">Баланс до</div>
                  <div class="p-0 col-12 col-lg fz-16">
                    {{ item?.finallyBefore }}
                    {{ item?.operation?.cryptocurrency_id?.code }}
                  </div>
                </div>
                <div class="row m-0 mb-1">
                  <div class="p-0 col-12 col-lg-2 fz-16">Баланс после</div>
                  <div class="p-0 col-12 col-lg fz-16">
                    {{ item?.finallyAfter }}
                    {{ item?.operation?.cryptocurrency_id?.code }}
                  </div>
                </div>
                <div class="row m-0 mb-1">
                  <div class="p-0 col-12 col-lg-2 fz-16">Взято в работу</div>
                  <div class="p-0 col-12 col-lg fz-16">
                    {{ localTime(item?.operation?.hired_at) }}
                  </div>
                </div>
                <div class="row m-0">
                  <div class="p-0 col-12 col-lg-2 fz-16">Завершено</div>
                  <div class="p-0 col-12 col-lg fz-16">
                    {{
                      moment(item?.operation?.updated_at).format(
                        "DD-MM-YYYY HH:mm"
                      )
                    }}
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="history_pagen-block">
        <pagination
          v-model="page"
          :records="totalChecksCount"
          :per-page="perPage"
          @paginate="myCallback"
          :hide-count="true"
          :margin-pages="2"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Circle from "@/components/CircleProgress.vue";
import { useHistoryStore } from "@/stores/useHistoryStore";
import pagination from "v-pagination-3";
import moment from "moment";
export default {
  props: ["currencies", "history"],
  components: {
    pagination,
    Circle,
  },
  filters: {
    format: (val) => `${val}`.replace(/(\d)(?=(\d{4})+([^\d]|$))/g, "$1 "),
  },
  data: () => ({
    loading: false,
    page: 1,
    perPage: 10,
    showPayLinks: false,
    showTooltip: false,
    isBuyVisible: false,
    isDepositVisible: false,
    operationSumm: 0,
    status: sessionStorage.getItem("status_id"),
    isAddCardVisible: false,
    operation_link: "",
    comment: "",
    moment: moment,
    errorText: {
      amount: "",
      comment: "",
      operation_link: "",
      message: "",
    },
    history: [],
  }),
  setup() {
    const historyStore = useHistoryStore();
    return { historyStore };
  },
  computed: {
    totalChecksCount() {
      return this.history?.length;
    },
    paginatedElements() {
      const start = (this.page - 1) * this.perPage;
      const end = start + this.perPage;
      return Object.values(this.history).slice(start, end);
    },
    totalPages() {
      const pages = Math.ceil(this.totalChecksCount / this.perPage);
      return pages > 0 ? pages : 1;
    },
  },

  methods: {
    //Получаем историяю
    async getHistory() {
      this.loading = true;
      await this.historyStore.fetchHistory();
      this.history = this.historyStore.history;
      this.loading = false;
    },
    async clipboardPaste() {
      try {
        const clipboardText = await navigator.clipboard.readText();
        this.operation_link = clipboardText;
      } catch (error) {
        console.error("Failed to read from clipboard:", error);
      }
    },

    localTime(time) {
      var testDateUtc = moment.utc(time);
      var localDate = moment(testDateUtc).local();
      return localDate.format("DD-MM-YYYY HH:mm");
    },
  },
  mounted() {
    this.getHistory();
  },
};
</script>

<style scoped>
.bg_lightgreen {
  background: #4aa485;
}

.bg_green {
  background: #35634f;
}

.bg_black {
  background: #212121;
}

.border_green {
  border-color: #35634f;
}

.description h6 {
  text-decoration: underline;
  text-underline-offset: 5px;
}

#active_pays_accordeon .border_green {
  border-top: 1px solid #35634f;
  border-radius: 5px;
}

.add_balance {
  max-width: 93px;
  text-align: center;
  color: #fff;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.balance_info {
  color: #000;
}

.balance_info {
  color: #fff;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.balance_info span:nth-child(2) {
  font-size: 30.5px;
}

.balance_info span:last-child,
.balance_info span:first-child {
  font-size: 20px;
}

h6 {
  display: block;
}

.operation_form {
  color: #000;
  background: #35634f;
}

.operation_form textarea {
  height: 92px;
}

.accordion-button:not(.collapsed)::after,
.accordion-button::after {
  background-image: url(/public/static/img/ico/ico-accordeon.svg);
  width: 30px;
  height: 15px;
}

.active_pays_header {
  width: 90%;
}

.active_pays_header :not(:first-child) {
  font-size: 16px;
}

#active_pays_accordeon {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.fz-16 {
  font-size: 16px;
}

.links {
  height: 135px;
  border-radius: 5px;
}

.links .left {
  border-right: 1px solid #35634f;
}

.btn-outline-danger:hover {
  background-color: #ff6d6d;
}

.btn-outline-danger {
  color: #fff;
  border-color: #ff6d6d;
  font-size: 16px;
  width: -moz-fit-content;
  width: fit-content;
}

.danger {
  color: #ff6d6d;
}

.success {
  color: #74ed79;
}

.description h6 {
  display: flex;
  align-items: flex-end;
  width: fit-content;
  gap: 10px;
}

.pay_link_tooltip {
  z-index: 9999;
}

.my_link {
  display: block;
  width: 260px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}

.no-active {
  border-radius: 5px;
  border: 1px solid #35634f;
  font-size: 20px;
  line-height: normal;
}
</style>
