import { defineStore } from 'pinia';
import { useAuthStore, usedUrl } from '@/stores/useAuthStore.js';

export const useHistoryStore = defineStore('history', {
  state: () => ({
    history: null,
  }),
  actions: {
    async fetchHistory() {
      const authStore = useAuthStore();
      try {
        const response = await fetch(`${usedUrl}/valutix_pay_operation_history`, {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": `Bearer ${authStore.token}`
          },
          method: "GET",
        });
        if (response.ok) {
          const actualData = await response.json();
          this.history = actualData.data;
          this.history = this.history
          .filter(item => item?.operation?.operation_title === 'Оплата счета')
          .map(item => ({
            ...item,
            finallyAmount: parseFloat(item?.operation?.amount) / parseFloat(item?.operation?.withdrawal?.cryptocurrency?.delimiter),
            finallyBefore: parseFloat(item?.balances?.before) / parseFloat(item?.operation?.withdrawal?.cryptocurrency?.delimiter),
            finallyAfter: parseFloat(item?.balances?.after) / parseFloat(item?.operation?.withdrawal?.cryptocurrency?.delimiter),
          })).sort((a, b) => b.id - a.id) || [];
        }
        else {
          alert('Не удалось получить юзера');
        }
      } catch (e) {
        console.error('Ошибка:', e);
      }
    },
  },
});
