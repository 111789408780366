<template>
  <div
    class="d-flex align-items-center justify-content-center w-100 h-100"
    v-if="loading"
  >
    <Circle />
  </div>
  <Header
    v-if="this.$route.name !== 'special' && !loading"
    :urlParams="urlParams"
    :merchant_payment="merchant_payment"
    :laying="laying"
    :cryptocurrency="cryptocurrency"
    :cryptocurrencyAmount="cryptocurrencyAmount"
  />
  <router-view
    v-if="!loading"
    :balances="balances"
    :currency="currency"
    :currencyAmount="currencyAmount"
    :cryptocurrencyAmount="cryptocurrencyAmount"
    :user="user"
    :urlParams="urlParams"
    :merchant_payment="merchant_payment"
    :cryptocurrency="cryptocurrency"
    :cryptocurrency_balance="cryptocurrency_balance"
    :limits="limits"
    @login_user="login_user"
    @urlParams="getUrlParamsFromLogin"
  />
  <VerificationModal :user="user" @verify="verify" />
</template>

<script>
import { useAuthStore } from "@/stores/useAuthStore";
import { useUserStore } from "@/stores/useUserStore";
import Circle from "@/components/CircleProgress.vue";
import "./css/main.css";
import Header from "@/components/Header.vue";
import VerificationModal from "@/components/Verification-modal.vue";
export default {
  name: "App",
  components: {
    Header,
    VerificationModal,
    Circle,
  },
  data() {
    return {
      loading: false,
      onLoadUser: false,
      balances: [],
      currency: [],
      user: [],
      urlParams: null,
      merchant_payment: [],
      mylimits: [],
      cryptocurrency: "USDT",
      cryptocurrency_balance: null,
      limits: {
        day_limit: this.user?.status?.id !== 3 ? 10000 : 700000,
        mounth_limit: this.user?.status?.id !== 3 ? 50000 : 3000000,
        my_day_limit: 0,
        my_mounth_limit: 0,
      },
      laying: false,
    };
  },

  setup() {
    const authStore = useAuthStore();
    const userStore = useUserStore();
    return { authStore, userStore };
  },

  computed: {
    currencyAmount() {
      return (
        this.merchant_payment.currency_amount /
        this.merchant_payment?.merchant?.currency?.delimiter
      );
    },
    cryptocurrencyAmount() {
      return (
        this.merchant_payment.cryptocurrency_amount /
        this.merchant_payment?.merchant?.currency?.delimiter
      );
    },
  },

  methods: {
    login_user() {
      this.$router.push("/account");
      this.getInfo();
    },
    logout() {
      this.user = null;
      this.$router.push("/login");
    },
    async getInfo() {
      this.loading = true;
      await this.getMerchantPayment();
      this.getUser();
      this.getLimits();
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    },
    verify() {
      this.getUser();
    },
    //getUser
    async getUser() {
      this.emitter.emit("turnOnPusher");
      await this.userStore.fetchUser();
      this.user = this.userStore.user;
      this.balances = this.userStore.balances;
      this.cryptocurrency_balance = this.balances
        ?.find((b) => b.currency.code == "USDT")
        ?.finally_value.toFixed(2);
    },

    getUrlParamsFromLogin(data) {
      this.urlParams = data;
    },

    //getParams
    getParams() {
      this.urlParams = sessionStorage.getItem("id") || new URLSearchParams(window.location.search).get("id");
      if (this.urlParams) {
        sessionStorage.setItem("id", this.urlParams);
        console.log("Параметр id:", this.urlParams);
      } else {
        console.log("Параметр id отсутствует");
        if (window.location.pathname !== "/special") {
          this.$router.push("/no-param");
        }
      }
    },

    //getMerchantPayment
    getMerchantPayment() {
      return axios
        .get(this.$api_address + `/merchant_payments/${this.urlParams}`, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${this.authStore.token}`,
          },
        })
        .then((response) => {
          this.merchant_payment = response.data.data;
          if(this.merchant_payment.status === 'Успех' || this.merchant_payment.status === 'Отмена') {
            this.$router.push("/finished");
            return;
          }
          this.currency = this.merchant_payment.merchant.currency.code;
        })
        .catch((error) => {
          console.error("Error fetching user:", error);
        });
    },
    //Получаем лимиты
    getLimits() {
      return axios
        .get(
          this.$api_address +
            `/valutix_pay_users/${this.authStore.user_ID}/limits`,
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${this.authStore.token}`,
            },
            params: {
              merchant_id: this.merchant_payment.merchant.id,
            },
          }
        )
        .then((response) => {
          this.mylimits = response.data;
          this.limits.my_day_limit = this.mylimits.limits.day;
          this.limits.my_mounth_limit = this.mylimits.limits.month;
        })
        .catch((error) => {
          console.error("Ошибка получения лимитов:", error);
        });
    },
  },

  watch: {
    "user.status": function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.limits.day_limit = this.user?.status?.id !== 3 ? 10000 : 700000;
        this.limits.mounth_limit =
          this.user?.status?.id !== 3 ? 50000 : 3000000;
      }
    },
  },

  created() {
    if (
      (window.location.pathname == "/account" ||
      window.location.pathname == "/invoice") &&
      this.authStore.token
    ) {
      this.getParams();
      this.getInfo();
    }

    // if (
    //   this.authStore.token &&
    //   window.location.pathname !== "/no-param" &&
    //   window.location.pathname !== "/special"
    // ) {
    //   this.getInfo();
    // }
  },

  mounted() {
    this.getParams();

    this.emitter.on("finish_timer", () => {
      sessionStorage.clear();
      this.urlParams = false;
      this.$router.push("/finish");
    });
    this.emitter.on("userUpdate", (data) => {
      if (data.id == this.user.id) {
        this.user = data;
        this.getLimits();
        if (this.user.status.id == 5) {
          this.logout();
        }
      }
    });
    this.emitter.on("merchantPaymentUpdate", (data) => {
      if (data.status == "Успех" || data.status == "Отмена") {
        sessionStorage.setItem("step", 4);
        sessionStorage.setItem("laying", true);
        this.$router.push("/invoice");
        this.laying = true;
      }
    });

    this.emitter.on("balanceUpdate", (balance) => {
      this.getUser();
      this.getLimits();
    });
  },
};
</script>
